import { cn } from '../../utils';
import { HTMLAttributes } from 'react';

// Define the props for the root component of the layout footer
type SingleAddLayoutFooterRootProps = HTMLAttributes<HTMLDivElement>;

// Root component of the layout footer
export const SingleAddLayoutFooterRoot = ({
    className,
    ...props
}: SingleAddLayoutFooterRootProps) => {
    return (
        <div className="fixed inset-x-0 bottom-0 h-15 w-full border-t border-t-neutral-1900 bg-white max-md:shadow-elevation md:h-20">
            <div
                className={cn(
                    'mx-auto flex size-full max-w-5xl items-center justify-end px-4 md:px-6',
                    className,
                )}
                {...props}
            />
        </div>
    );
};

// Component for the actions section of the layout footer
export const SingleAddLayoutFooterActions = ({
    className,
    ...props
}: HTMLAttributes<HTMLDivElement>) => {
    return <div className={cn('flex items-center space-x-5', className)} {...props} />;
};

// Object that contains the root and actions components of the layout footer
export const SingleAddLayoutFooter = {
    Root: SingleAddLayoutFooterRoot,
    Actions: SingleAddLayoutFooterActions,
};
