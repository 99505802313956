import { ReactNode } from 'react';
import { Skeleton } from '../skeleton';
import { SingleAddLayoutContent } from './layout-content';
import { SingleAddLayoutFooter } from './layout-footer';
import { SingleAddLayoutHeader } from './layout-header';
import { SingleAddLayoutRoot } from './layout-root';

export const SingleAddLayoutSkeleton = ({
    activeStep,
    totalSteps,
    children,
}: {
    activeStep: number;
    totalSteps: number;
    children: ReactNode;
}) => {
    return (
        <SingleAddLayoutRoot>
            <SingleAddLayoutHeader.Root>
                <SingleAddLayoutHeader.Progress>
                    <Skeleton className="h-10 w-20" />
                </SingleAddLayoutHeader.Progress>
                <SingleAddLayoutHeader.Title>
                    <Skeleton className="h-10 w-40" />
                </SingleAddLayoutHeader.Title>
                <SingleAddLayoutHeader.Actions>
                    <Skeleton className="h-10 w-20" />
                </SingleAddLayoutHeader.Actions>
                <SingleAddLayoutHeader.ProgressBar activeStep={activeStep} steps={totalSteps} />
            </SingleAddLayoutHeader.Root>
            <SingleAddLayoutContent.Root>
                <SingleAddLayoutContent.Header>
                    <Skeleton className="h-8 w-40" />
                    <Skeleton className="w-200 h-4" />
                </SingleAddLayoutContent.Header>
                {children}
            </SingleAddLayoutContent.Root>

            <SingleAddLayoutFooter.Root>
                <SingleAddLayoutFooter.Actions>
                    <Skeleton className="h-10 w-20" />
                </SingleAddLayoutFooter.Actions>
            </SingleAddLayoutFooter.Root>
        </SingleAddLayoutRoot>
    );
};
