import { cn } from '../../utils';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import type { ComponentPropsWithoutRef, ElementRef } from 'react';
import { forwardRef } from 'react';
import { useTab } from './use-tab';
import { Button } from '../button';
import { extend } from '../components';

const TabsRoot = TabsPrimitive.Root;
const TabsList = TabsPrimitive.List;
const TabsContent = TabsPrimitive.Content;

const TabsTrigger = forwardRef<
    ElementRef<typeof TabsPrimitive.Trigger>,
    ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ children, className, ...props }, forwardedRef) => (
    <TabsPrimitive.Trigger
        className={cn(
            'focus-visible:ring-ring inline-flex items-center justify-center whitespace-nowrap py-4 text-sm font-semibold text-neutral-60 transition-all hover:text-neutral-200 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
            'data-[state=active]:text-primary-600 data-[state=active]:underline data-[state=active]:decoration-[3px] data-[state=active]:underline-offset-[18px]',
            className,
        )}
        {...props}
        ref={forwardedRef}
    >
        {children}
    </TabsPrimitive.Trigger>
));

TabsTrigger.displayName = 'TabsTrigger';

const TabControlsList = extend(TabsList, {
    className: 'inline-flex h-8 w-fit rounded-md bg-neutral-6 p-1',
});

const TabControlsTrigger = ({ children, ...props }: TabsPrimitive.TabsTriggerProps) => {
    return (
        <TabsPrimitive.Trigger {...props} asChild>
            <Button additions="tab" variant="subtle" className="h-7">
                {children}
            </Button>
        </TabsPrimitive.Trigger>
    );
};

export {
    TabsRoot,
    TabsList,
    TabsTrigger,
    TabsContent,
    TabControlsList,
    TabControlsTrigger,
    useTab,
};
